import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { DatabaseProvider } from './context/DatabaseContext';
import { AuthProvider } from './context/AuthContext';
import App from './App';
import './index.css';
import ErrorBoundary from './components/ErrorBoundary';
import { ThemeProvider } from './context/ThemeContext';
import { withSuspense, withRetry } from './utils/routeUtils';

// Apply dark mode class immediately to prevent flash of light theme
const savedTheme = localStorage.getItem('site-theme') || 'dark';
if (savedTheme === 'dark') {
  document.documentElement.classList.add('dark');
  document.documentElement.classList.add('dark-theme');
} else {
  document.documentElement.classList.remove('dark');
  document.documentElement.classList.add('light-theme');
}

// Force dark mode in localStorage if it's not set
if (!localStorage.getItem('site-theme')) {
  localStorage.setItem('site-theme', 'dark');
}

// Service Worker Registration
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('SW registered:', registration);
      })
      .catch(error => {
        console.log('SW registration failed:', error);
      });
  });
}

// Page components
const Home = withRetry(() => import('./pages/Home'));
const About = withRetry(() => import('./pages/About'));
const Contact = withRetry(() => import('./pages/Contact'));
const Blog = withRetry(() => import('./pages/Blog'));
const AdminLogin = withRetry(() => import('./pages/AdminLogin'));
const AdminDashboard = withRetry(() => import('./pages/AdminDashboard'));
const ServiceDetail = withRetry(() => import('./pages/ServiceDetail'));
const Services = withRetry(() => import('./pages/Services'));
const DigitalTransformation = withRetry(() => import('./pages/services/DigitalTransformation'));
const AIMLSolutions = withRetry(() => import('./pages/services/AIMLSolutions'));
const CloudNativeEngineering = withRetry(() => import('./pages/services/CloudNativeEngineering'));
const DataStrategy = withRetry(() => import('./pages/services/DataStrategy'));
const GenerativeAI = withRetry(() => import('./pages/services/GenerativeAI'));
const EnterpriseDataManagement = withRetry(() => import('./pages/services/EnterpriseDataManagement'));
const HealthCheck = withRetry(() => import('./pages/HealthCheck'));
const BlogDetail = withRetry(() => import('./pages/BlogDetail'));
const AIAdvisory = withRetry(() => import('./pages/services/AIAdvisory'));
const DataGovernance = withRetry(() => import('./pages/services/DataGovernance'));
const Privacy = withRetry(() => import('./pages/Privacy'));
const Terms = withRetry(() => import('./pages/Terms'));

// Router configuration with future flags enabled
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorBoundary />,
      children: [
        { index: true, element: withSuspense(Home) },
        { path: 'about', element: withSuspense(About) },
        { path: 'contact', element: withSuspense(Contact) },
        { path: 'blog', element: withSuspense(Blog) },
        { path: 'blog/:id', element: withSuspense(BlogDetail) },
        { path: 'admin', element: withSuspense(AdminLogin) },
        { path: 'admin/*', element: withSuspense(AdminDashboard) },
        {
          path: 'services',
          children: [
            { index: true, element: withSuspense(Services) },
            { path: 'digital-transformation', element: withSuspense(DigitalTransformation) },
            { path: 'ai-ml-solutions', element: withSuspense(AIMLSolutions) },
            { path: 'cloud-native-engineering', element: withSuspense(CloudNativeEngineering) },
            { path: 'data-strategy', element: withSuspense(DataStrategy) },
            { path: 'generative-ai', element: withSuspense(GenerativeAI) },
            { path: 'enterprise-data-management', element: withSuspense(EnterpriseDataManagement) },
            { path: 'ai-advisory', element: withSuspense(AIAdvisory) },
            { path: 'data-governance', element: withSuspense(DataGovernance) },
            { path: ':slug', element: withSuspense(ServiceDetail) }
          ]
        },
        { path: 'health', element: withSuspense(HealthCheck) },
        { path: 'privacy', element: withSuspense(Privacy) },
        { path: 'terms', element: withSuspense(Terms) }
      ]
    }
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }
  }
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <DatabaseProvider>
          <RouterProvider router={router} />
        </DatabaseProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
