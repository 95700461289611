import React, { createContext, useContext, useState, useEffect } from 'react';
import localforage from 'localforage';
import { initializeDatabase } from '../database/initDB';
import { getDefaultServices } from '../database/seedManager';

const DatabaseContext = createContext();

// Initialize stores
const servicesStore = localforage.createInstance({
  name: 'services'
});

// Retry configuration
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const retryOperation = async (operation, retries = MAX_RETRIES) => {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0) {
      console.warn(`Operation failed, retrying... (${retries} attempts remaining)`);
      await sleep(RETRY_DELAY);
      return retryOperation(operation, retries - 1);
    }
    throw error;
  }
};

export const DatabaseProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState(null);

  const loadServices = async () => {
    try {
      console.log('Loading services...');
      setLoading(true);
      setError(null);

      // Get services from store with retry mechanism
      const serviceKeys = await retryOperation(() => servicesStore.keys());
      console.log('Service keys found:', serviceKeys);

      if (serviceKeys.length === 0) {
        console.log('No services found in store, adding default services');
        const defaultServices = getDefaultServices();

        // Store default services with retry mechanism
        for (const service of defaultServices) {
          await retryOperation(() => servicesStore.setItem(service.id, service));
        }

        setServices(defaultServices);
        setLastSyncTime(new Date().toISOString());
        console.log('Default services loaded:', defaultServices.length);
        return;
      }

      const loadedServices = await Promise.all(
        serviceKeys.map(async (key) => {
          try {
            return await retryOperation(() => servicesStore.getItem(key));
          } catch (err) {
            console.error(`Error loading service ${key}:`, err);
            return null;
          }
        })
      );

      // Filter out invalid services and duplicates
      const validServices = loadedServices.filter(service =>
        service &&
        service.id &&
        service.slug &&
        service.title
      );

      const uniqueServices = Array.from(
        new Map(validServices.map(s => [s.slug, s])).values()
      );

      console.log('Unique valid services loaded:', uniqueServices.length);
      setServices(uniqueServices);
      setLastSyncTime(new Date().toISOString());

      if (uniqueServices.length === 0) {
        throw new Error('No valid services found');
      }
    } catch (err) {
      console.error('Error loading services:', err);
      setError({
        message: err.message,
        timestamp: new Date().toISOString(),
        code: err.code || 'UNKNOWN_ERROR'
      });
    } finally {
      setLoading(false);
    }
  };

  const clearError = () => setError(null);

  const refreshServices = async () => {
    try {
      await loadServices();
      return true;
    } catch (err) {
      console.error('Error refreshing services:', err);
      return false;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        console.log('Initializing database...');
        await retryOperation(() => initializeDatabase());
        console.log('Database initialized, loading services...');
        await loadServices();
        setIsInitialized(true);
        console.log('Initialization complete');
      } catch (err) {
        console.error('Error during initialization:', err);
        setError({
          message: err.message,
          timestamp: new Date().toISOString(),
          code: err.code || 'INITIALIZATION_ERROR'
        });
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  const getServiceBySlug = (slug) => {
    if (!slug) return null;
    return services.find(s => s.slug.toLowerCase() === slug.toLowerCase());
  };

  const value = {
    services,
    loading,
    error,
    isInitialized,
    lastSyncTime,
    loadServices,
    refreshServices,
    clearError,
    getServiceBySlug,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
};

export const useDatabase = () => {
  const context = useContext(DatabaseContext);
  if (!context) {
    throw new Error('useDatabase must be used within a DatabaseProvider');
  }
  return context;
};

export default DatabaseContext;
