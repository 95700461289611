import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar, Footer, ContactBar, ScrollToTop } from './components';
import { useTheme } from './context/ThemeContext';

const App = () => {
  const { theme } = useTheme();

  // Use data-theme attribute for easier component styling
  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    return () => document.body.removeAttribute('data-theme');
  }, [theme]);

  return (
    <div className={`flex flex-col min-h-screen ${theme === 'dark' ? 'bg-gray-900' : 'bg-white'}`}>
      <ScrollToTop />
      <ContactBar />
      <Navbar />
      <main className={`flex-grow ${theme === 'dark' ? 'bg-gray-900' : 'bg-white'}`}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default App;
