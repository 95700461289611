import React from 'react';
import { MapPin, Phone, Mail } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

/**
 * ContactBar component that displays contact information in the header
 * Currently showing only email, with address and phone hidden for future use
 */
const ContactBar = () => {
    const { theme } = useTheme();

    return (
        <div className="bg-gray-900 text-white py-2">
            <div className="container mx-auto px-4 flex justify-center items-center text-sm">
                {/* Address - Hidden for future use
                <div className="flex items-center mb-2 md:mb-0 mr-8">
                    <MapPin size={16} className="mr-2" />
                    <span>1234 Technology Drive, San Francisco, CA 94107</span>
                </div>
                */}

                {/* Phone - Hidden for future use 
                <div className="flex items-center mr-8">
                    <Phone size={16} className="mr-2" />
                    <span>+1 (555) 123-4567</span>
                </div>
                */}

                <div className="flex items-center">
                    <Mail size={16} className="mr-2" />
                    <a
                        href="mailto:info@sharedoxygen.com"
                        className="hover:text-blue-400 transition-colors"
                    >
                        info@sharedoxygen.com
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ContactBar; 