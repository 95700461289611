import React from 'react';
import { useTheme } from '../context/ThemeContext';
import logoLightImage from './logo.png';

/**
 * Logo component that displays the Shared Oxygen logo
 * - Uses original logo for light theme
 * - Creates a pure SVG dark-theme version with no white background
 */
const Logo = ({
  className = "",
  width = "auto",
  height = "auto",
  maxWidth = "75px",
  alt = "Shared Oxygen"
}) => {
  const { theme } = useTheme();

  // Combined className for external styling
  const combinedClassName = `logo ${className}`;

  // SVG viewBox dimensions
  const viewBoxSize = 200;

  // Calculate dimensions
  const actualWidth = width === 'auto' ? maxWidth : width;
  const actualHeight = height === 'auto' ? 'auto' : height;

  // For dark theme, use pure SVG with no background
  if (theme === 'dark') {
    return (
      <svg
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        width={actualWidth}
        height={actualHeight}
        className={combinedClassName}
        style={{
          maxWidth,
          display: 'block',
        }}
        aria-label={alt}
      >
        {/* Outer Circle - Teal */}
        <circle
          cx={viewBoxSize / 2}
          cy={viewBoxSize / 2}
          r={viewBoxSize * 0.45}
          fill="none"
          stroke="rgba(20, 184, 166, 0.8)"
          strokeWidth="4"
        />

        {/* Middle Circle - Blue */}
        <circle
          cx={viewBoxSize / 2 - viewBoxSize * 0.15}
          cy={viewBoxSize / 2 + viewBoxSize * 0.05}
          r={viewBoxSize * 0.3}
          fill="none"
          stroke="rgba(59, 130, 246, 0.8)"
          strokeWidth="4"
        />

        {/* Inner Circle - Purple */}
        <circle
          cx={viewBoxSize / 2 + viewBoxSize * 0.15}
          cy={viewBoxSize / 2 + viewBoxSize * 0.05}
          r={viewBoxSize * 0.3}
          fill="none"
          stroke="rgba(139, 92, 246, 0.8)"
          strokeWidth="4"
        />

        {/* Text - White with blue glow */}
        <text
          x={viewBoxSize / 2}
          y={viewBoxSize * 0.8}
          textAnchor="middle"
          fill="white"
          fontSize="18"
          fontWeight="bold"
          fontFamily="Arial, sans-serif"
          letterSpacing="1"
          filter="url(#glow)"
        >
          SHARED OXYGEN
        </text>

        {/* Filters for text glow */}
        <defs>
          <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur stdDeviation="2" result="blur" />
            <feFlood floodColor="rgba(59, 130, 246, 0.3)" result="color" />
            <feComposite in="color" in2="blur" operator="in" result="glow" />
            <feMerge>
              <feMergeNode in="glow" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
      </svg>
    );
  }

  // For light theme, use the original logo
  return (
    <img
      src={logoLightImage}
      alt={alt}
      className={combinedClassName}
      style={{
        width,
        height,
        maxWidth,
        objectFit: 'contain',
        display: 'block'
      }}
      width={width === 'auto' ? '100%' : width}
      height={height === 'auto' ? '100%' : height}
      onError={(e) => {
        console.error('Error loading logo image:', e);
        e.target.onerror = null;
        e.target.style.display = 'none';
      }}
    />
  );
};

export default Logo;
