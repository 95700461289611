import React from 'react';
import { Link } from 'react-router-dom';
import {
  X,
  Linkedin,
  Facebook,
  Instagram,
  Mail,
  Phone,
  MapPin,
  ExternalLink,
  Heart,
  Youtube
} from 'lucide-react';
import Logo from '../assets/logo';
import { useTheme } from '../context/ThemeContext';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { theme } = useTheme();

  const socialLinks = [
    {
      icon: Linkedin,
      href: 'https://linkedin.com/company/sharedoxygen',
      label: 'LinkedIn',
      color: 'hover:text-blue-700'
    },
    {
      icon: Facebook,
      href: 'https://facebook.com/sharedoxygen',
      label: 'Facebook',
      color: 'hover:text-blue-600'
    },
    {
      icon: X,
      href: 'https://twitter.com/sharedoxygen',
      label: 'X (Twitter)',
      color: 'hover:text-gray-900'
    },
    {
      icon: Youtube,
      href: 'https://youtube.com/sharedoxygen',
      label: 'YouTube',
      color: 'hover:text-red-600'
    },
    {
      icon: Instagram,
      href: 'https://instagram.com/sharedoxygen',
      label: 'Instagram',
      color: 'hover:text-pink-500'
    },
    {
      // Using a custom component for TikTok since it's not in Lucide
      icon: ({ size, className }) => (
        <svg
          width={size || 20}
          height={size || 20}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        >
          <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
        </svg>
      ),
      href: 'https://tiktok.com/@sharedoxygen',
      label: 'TikTok',
      color: 'hover:text-pink-400'
    }
  ];

  const quickLinks = [
    { name: 'Home', path: '/' },
    { name: 'Services', path: '/services' },
    { name: 'About Us', path: '/about' },
    { name: 'Blog', path: '/blog' },
    { name: 'Contact', path: '/contact' }
  ];

  const serviceLinks = [
    { name: 'Digital Transformation', path: '/services/digital-transformation' },
    { name: 'AI & ML Solutions', path: '/services/ai-ml-solutions' },
    { name: 'Generative AI', path: '/services/generative-ai' },
    { name: 'Data Strategy', path: '/services/data-strategy' },
    { name: 'Cloud Native Engineering', path: '/services/cloud-native-engineering' },
    { name: 'AI Advisory', path: '/services/ai-advisory' },
    { name: 'Enterprise Data Management', path: '/services/enterprise-data-management' }
  ];

  return (
    <footer className={`${theme === 'dark' ? 'bg-gray-900 text-gray-300' : 'bg-gray-50 text-gray-600'} pt-16 pb-8`}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div>
            <div className="mb-6">
              <Logo maxWidth="90px" alt="Shared Oxygen" />
            </div>
            <h3 className={`text-lg font-semibold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>
              About Us
            </h3>
            <p className="mb-4">
              Shared Oxygen provides innovative technology solutions to help businesses thrive in today's digital landscape.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${theme === 'dark' ? social.color : 'text-blue-600 hover:text-blue-800'} transition-colors`}
                  aria-label={social.label}
                >
                  <social.icon size={20} />
                </a>
              ))}
            </div>
          </div>

          <div>
            <h3 className={`text-lg font-semibold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>
              Quick Links
            </h3>
            <ul className="space-y-2">
              {quickLinks.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className={`${theme === 'dark' ? 'hover:text-blue-400' : 'hover:text-blue-600'} transition-colors`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className={`text-lg font-semibold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>
              Services
            </h3>
            <ul className="space-y-2">
              {serviceLinks.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className={`${theme === 'dark' ? 'hover:text-blue-400' : 'hover:text-blue-600'} transition-colors`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className={`text-lg font-semibold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>
              Contact Us
            </h3>
            <ul className="space-y-4">
              {/* Address - Hidden for future use
              <li className="flex items-center">
                <MapPin size={18} className="mr-2 flex-shrink-0" />
                <span>1234 Technology Drive, San Francisco, CA 94107</span>
              </li>
              */}
              {/* Phone - Hidden for future use
              <li className="flex items-center">
                <Phone size={18} className="mr-2 flex-shrink-0" />
                <span>+1 (555) 123-4567</span>
              </li>
              */}
              <li className="flex items-center">
                <Mail size={18} className="mr-2 flex-shrink-0" />
                <a
                  href="mailto:info@sharedoxygen.com"
                  className={`${theme === 'dark' ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-800'} transition-colors`}
                >
                  info@sharedoxygen.com
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={`border-t ${theme === 'dark' ? 'border-gray-800' : 'border-gray-200'} pt-8 mt-8 flex flex-col md:flex-row justify-between items-center`}>
          <div className="mb-4 md:mb-0">
            <p>&copy; {currentYear} Shared Oxygen. All rights reserved.</p>
          </div>
          <div className="flex space-x-6">
            <Link
              to="/privacy"
              className={`${theme === 'dark' ? 'hover:text-blue-400' : 'hover:text-blue-600'} transition-colors`}
              onClick={() => window.scrollTo(0, 0)}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms"
              className={`${theme === 'dark' ? 'hover:text-blue-400' : 'hover:text-blue-600'} transition-colors`}
              onClick={() => window.scrollTo(0, 0)}
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
