import React, { lazy, Suspense } from 'react';

// Loading component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

// Lazy load page components with automatic retry
export const withRetry = (importFn) => {
  return lazy(() => importFn().catch((error) => {
    console.error('Failed to load module:', error);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(importFn());
      }, 1000);
    });
  }));
};

// Wrap component with Suspense
export const withSuspense = (Component) => (
  <Suspense fallback={<LoadingFallback />}>
    <Component />
  </Suspense>
); 