import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, Sun, Moon } from 'lucide-react';
import Logo from '../assets/logo.jsx';
import { useTheme } from '../context/ThemeContext';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();

  // Track scroll position for navbar styling
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navLinks = [
    { to: '/', label: 'Home' },
    { to: '/services', label: 'Services' },
    { to: '/about', label: 'About' },
    { to: '/contact', label: 'Contact' },
    { to: '/admin', label: 'Login' }
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-700'} ${scrolled ? 'shadow-md' : 'shadow-soft'} sticky top-0 z-50 transition-shadow duration-300`}>
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <div className="logo-container" style={{ minWidth: '100px', minHeight: '58px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Logo
              maxWidth="100px"
              width="100px"
              height="58px"
              className={`transition-all duration-300 ${scrolled ? 'h-11' : 'h-13'} logo-header`}
              alt="Shared Oxygen Logo"
            />
          </div>
        </Link>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-8">
          {navLinks.map((link) => {
            const isActive = location.pathname === link.to ||
              (link.to !== '/' && location.pathname.startsWith(link.to));

            return (
              <Link
                key={link.to}
                to={link.to}
                className={`relative py-2 hover:text-blue-600 transition-colors font-medium ${isActive
                  ? 'text-blue-600 font-semibold'
                  : theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}
                onClick={() => window.scrollTo(0, 0)}
              >
                {link.label}
                {isActive && (
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 rounded-full" />
                )}
              </Link>
            );
          })}

          {/* Theme Toggle Button */}
          <button
            onClick={toggleTheme}
            className={`p-2 rounded-full ${theme === 'dark' ? 'bg-gray-800 text-yellow-400 hover:bg-gray-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'} transition-colors`}
            aria-label={theme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
          >
            {theme === 'dark' ? <Sun size={18} /> : <Moon size={18} />}
          </button>
        </div>

        {/* Mobile Menu Toggle and Theme Button */}
        <div className="md:hidden flex items-center space-x-2">
          <button
            onClick={toggleTheme}
            className={`p-2 rounded-full ${theme === 'dark' ? 'bg-gray-800 text-yellow-400' : 'bg-gray-100 text-gray-700'} transition-colors`}
            aria-label={theme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
          >
            {theme === 'dark' ? <Sun size={18} /> : <Moon size={18} />}
          </button>

          <button
            onClick={toggleMenu}
            className={`${theme === 'dark' ? 'text-gray-100 hover:text-white hover:bg-gray-800' : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'} focus:outline-none p-2 rounded-md transition-colors`}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile Menu Overlay */}
        {isMenuOpen && (
          <div className="md:hidden fixed inset-0 bg-black/50 dark:bg-black/70 z-40" onClick={toggleMenu}>
            <div
              className={`fixed top-0 right-0 w-72 h-full shadow-lg p-6 animate-slide-left ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-700'}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-8">
                <div className="logo-container" style={{ minWidth: '75px', minHeight: '44px', display: 'flex', alignItems: 'center' }}>
                  <Logo
                    maxWidth="75px"
                    width="75px"
                    height="44px"
                    className="h-11 logo-mobile"
                    alt="Shared Oxygen Logo"
                  />
                </div>
                <button
                  onClick={toggleMenu}
                  className={`${theme === 'dark' ? 'text-gray-300 hover:text-white hover:bg-gray-800' : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'} p-2 rounded-md transition-colors`}
                  aria-label="Close menu"
                >
                  <X size={22} />
                </button>
              </div>
              <div className="space-y-4">
                {navLinks.map((link) => {
                  const isActive = location.pathname === link.to ||
                    (link.to !== '/' && location.pathname.startsWith(link.to));

                  return (
                    <Link
                      key={link.to}
                      to={link.to}
                      onClick={(e) => {
                        toggleMenu();
                        window.scrollTo(0, 0);
                      }}
                      className={`block px-2 py-3 transition-colors ${isActive
                        ? 'text-blue-600 font-semibold bg-blue-50 dark:bg-blue-900/40 rounded-md'
                        : `${theme === 'dark' ? 'text-gray-200 hover:text-blue-400' : 'text-gray-700 hover:text-blue-600'}`
                        }`}
                    >
                      {link.label}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
