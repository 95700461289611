import React from 'react';
import { useRouteError, Link } from 'react-router-dom';
import { Home, RefreshCw } from 'lucide-react';

const ErrorBoundary = () => {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
            <div className="max-w-lg w-full text-center">
                <div className="bg-white rounded-lg shadow-soft p-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Oops!</h1>
                    <div className="mb-6">
                        <p className="text-xl text-gray-600 mb-2">
                            {error.status === 404
                                ? "We couldn't find the page you're looking for."
                                : "Something went wrong on our end."}
                        </p>
                        <p className="text-gray-500">
                            {error.status === 404
                                ? "The page may have been moved or deleted."
                                : "Our team has been notified and we're working to fix the issue."}
                        </p>
                    </div>
                    <div className="flex justify-center space-x-4">
                        <Link
                            to="/"
                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <Home className="w-4 h-4 mr-2" />
                            Back to Home
                        </Link>
                        <button
                            onClick={() => window.location.reload()}
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <RefreshCw className="w-4 h-4 mr-2" />
                            Try Again
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorBoundary; 