import React, { createContext, useContext, useState, useEffect } from 'react';
import localforage from 'localforage';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadUser = async () => {
            try {
                const userStore = localforage.createInstance({ name: 'users' });
                const currentUser = await userStore.getItem('currentUser');
                if (currentUser) {
                    setUser(currentUser);
                }
            } catch (error) {
                console.error('Error loading user:', error);
            } finally {
                setLoading(false);
            }
        };

        loadUser();
    }, []);

    const login = async (email, password) => {
        try {
            const userStore = localforage.createInstance({ name: 'users' });
            const users = await userStore.getItem('users') || [];

            const user = users.find(u => u.email === email && u.password === password);
            if (user) {
                await userStore.setItem('currentUser', user);
                setUser(user);
                return { success: true };
            }
            return { success: false, message: 'Invalid credentials' };
        } catch (error) {
            console.error('Login error:', error);
            return { success: false, message: error.message };
        }
    };

    const logout = async () => {
        try {
            const userStore = localforage.createInstance({ name: 'users' });
            await userStore.removeItem('currentUser');
            setUser(null);
            return { success: true };
        } catch (error) {
            console.error('Logout error:', error);
            return { success: false, message: error.message };
        }
    };

    const value = {
        user,
        login,
        logout,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export default AuthContext; 